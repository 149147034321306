import React, { useState, useEffect, useCallback, useContext } from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import App from '../components/App'
import { breakpoints, spacing } from '../utils/styles'
import { Anchor } from '../components/shared/Anchors'

const BlogPage = (props) => {
  const siteTitle = get(this, 'props.data.site.siteMetadata.title')

  const { data } = props

  console.log(data)
  return (
    <App>
      <Helmet title={siteTitle}></Helmet>
      <div className="wrapper sectionContainer">
        <h3>Blog</h3>
        <div className="blogContainer flex-grid-thirds">
          {data.blogPosts.edges.map((edge) => {
            return (
              <div key={edge.node.id} className="col blogItem">
                {edge.node.heroImage && (
                  <Img
                    className="blogImage"
                    alt={edge.node.title}
                    fluid={edge.node.heroImage.fluid}
                  />
                )}
                <div className="p12">
                  <h5 className="mt16">{edge.node.title}</h5>
                  <div
                    className="mt16"
                    dangerouslySetInnerHTML={{
                      __html: edge.node.body.childMarkdownRemark.excerpt,
                    }}
                  />
                  <Anchor
                    key={`/blog/${edge.node.slug}`}
                    to={`/blog/${edge.node.slug}`}
                    className="mt16"
                  >
                    Citeste
                  </Anchor>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <style jsx>{`
        .wrapper {
          padding-top: ${spacing.lg}px;
        }
        .section-headline {
          margin-bottom: ${spacing.lg}px;
        }
        .blogContainer {
          padding-top: ${spacing.lg}px;
        }
        .flex-grid-thirds {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .flex-grid-thirds .col {
          flex-basis: 100%;
          margin-top: ${spacing.lg}px;
        }
        .blogImage {
        }
        @media (min-width: ${breakpoints.desktop}px) {
          .flex-grid-thirds .col {
            flex-basis: calc(33% - ${spacing.md}px);
          }
        }

        @media (min-width: ${breakpoints.phablet}px) and (max-width: ${breakpoints.desktop}px) {
          .flex-grid-thirds .col {
            flex-basis: calc(50% - ${spacing.md}px);
          }
        }
      `}</style>
    </App>
  )
}

export const query = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    blogPosts: allContentfulBlogPost {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            id
            fluid(maxWidth: 1440) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 200)
            }
          }
        }
      }
    }
  }
`

export default BlogPage
